import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITemperatureReport extends IBaseEntity {
    id?: number;
    date?: string;
    time?: string;
    temperature?: number;
    routeName?: string;
    driverName?: string;
    vehicleDescription?: string;
}

export class TemperatureReport extends BaseEntity implements ITemperatureReport {
    id?: number;
    date?: string;
    time?: string;
    temperature?: number;
    routeName?: string;
    driverName?: string;
    vehicleDescription?: string;

    constructor(report?) {
        super();
        if (report) {
            this.id = report.id;
            this.date = report.date;
            this.time = report.time;
            this.temperature = report.temperature;
            this.routeName = report.routeName;
            this.driverName = report.driverName;
            this.vehicleDescription = report.vehicleDescription;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TemperatureReport {
        return new TemperatureReport(this);
    }
}
